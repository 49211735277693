.toast > div {
  font-family: 'Press Start 2P', serif !important;
  font-size: 16px !important;
}

.toast > .Toastify__toast--success {
  background: #03449e;
}

.toast > .Toastify__toast--error {
  background: #e12d39;
}
